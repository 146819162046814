import styles from "./Dropdown.module.css";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Dropdown = (props) => {
  const toggleHandler = () => {
    props.onNavClick(false);
  };

  return (
    <div className={styles.dropdownBox}>
      <nav className={styles.dropdownContainer}>
        <Link to="#home" onClick={toggleHandler} className={styles.navLinks}>
          Home
        </Link>
        <Link to="#about" onClick={toggleHandler} className={styles.navLinks}>
          About
        </Link>
        <Link
          to="#reservations"
          onClick={toggleHandler}
          className={styles.navLinks}
        >
          Reservations
        </Link>
        <Link to="#contact" onClick={toggleHandler} className={styles.navLinks}>
          Contact
        </Link>
      </nav>
    </div>
  );
};

export default Dropdown;
