import styles from "./Franchise.module.css";

const Franchise = () => {
  return (
    <div className={styles.franchiseArea}>
      <section className={styles.franchiseSection}>
        <h3>
          +++ CONTACT US FOR FRANCHISE +++
        </h3>
      </section>
    </div>
  );
};

export default Franchise;
