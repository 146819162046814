import styles from "./BurgerLove.module.css";

import { ReactComponent as Unlimited } from "../svg/UNLIMITED BURGERLOVE.svg";

import ElMuerteDelTorro from "./../png/MuerteDelTorro.png";
import AvocadoLover from "./../png/AvocadoLover.png";

const BurgerLove = () => {
  return (
    <div className={styles.burgerLoveArea}>
      <section className={styles.loveSection}>
        <h1>
          <Unlimited className={styles.unlimited} />
        </h1>
        <article className={styles.loveCollection}>
          <img src={ElMuerteDelTorro} alt="Foto of a Burger named El Muerte del Torro" className={styles.elMuerteDelTorro} />
          <img src={AvocadoLover} alt="Illustration of Man making love to an Avocado" className={styles.avocadoLover} />
        </article>
      </section>
    </div>
  );
};

export default BurgerLove;
