import styles from "./VeganFriendly.module.css";
import { ReactComponent as WeAreVeganFriendly } from "../svg/WeAreVeganFriendly.svg";
import { ReactComponent as Veganotti } from "../svg/Veganotti.svg";

const VeganFriendly = () => {
  return (
    <div id="about" className={styles.veganArea}>
      <section className={styles.veganSection}>
        <Veganotti className={styles.veganotti} />
        <article className={styles.veganArticle}>
          <WeAreVeganFriendly className={styles.weAreVeganFriendly} />
          <p className={styles.veganText}>
            We have long been known for our many vegetarian creations, but we
            are also pioneers when it comes to vegan burgers and offer you the
            largest selection of vegan burgers in the Universe! You could choose
            between our homemade vegetable patty, inspired by a Persian family
            recipe, Tindle`s Plant Based Chicken or Beyond Meat's vegan meat
            substitute. We also offer some vegan sauces. Come by and get amazed!
          </p>
        </article>
      </section>
    </div>
  );
};

export default VeganFriendly;
