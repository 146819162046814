import Header from './components/Header';
import Home from './components/Home';
import Franchise from './components/Franchise';
import './App.css';
import VeganFriendly from './components/VeganFriendly';
import KidsCorner from './components/KidsCorner';
import Drinks from './components/Drinks';
import BurgerLove from './components/BurgerLove';
import Reservations from './components/Reservations';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Header />
      <Franchise />
      <Home />
      <VeganFriendly />
      <KidsCorner />
      <Drinks />
      <BurgerLove />
      <Reservations />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
