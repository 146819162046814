import styles from "./KidsCorner.module.css";
import { ReactComponent as WeHaveA } from "../svg/WeHaveA.svg";

import KidsCornerLogo from "../png/KidsCornerLogo.png";
import KidsMenuPic from "../png/KidsMenuPic.png";

const KidsCorner = () => {
  return (
    <div className={styles.kidsArea}>
      <section className={styles.kidsSection}>
        <article className={styles.kidsArticle}>
          <WeHaveA className={styles.weHaveA} />
          <img src={KidsCornerLogo} className={styles.kidsMenuLogo} alt="Kids Corner Logo" />
          <p className={styles.kidsText}>
            We are kids with kids! That is why we offer a special menu for children and
            welcome families to feel super-comfy with us. Let`s demand the world to be more family-friendly!
            To quote the wise Baby Spice: "It`s the only way to be!"  
          </p>
        </article>
        <img src={KidsMenuPic} className={styles.kidsMenuPic} alt="Space Burger Kids Menu Foto" ></img>
      </section>
    </div>
  );
};

export default KidsCorner;
