import styles from "./Contact.module.css";
import { ReactComponent as ContactHeader } from "../svg/CONTACT.svg";
import { ReactComponent as Insta } from "../svg/insta sign.svg";
import { ReactComponent as Facebook } from "../svg/facebook zuckerberg.svg";

const Contact = () => {
  return (
    <div className={styles.contactArea} id="contact">
      <section className={styles.contactSection}>
        <ContactHeader />
        <article className={styles.contactCollection}>
          <div className={styles.contactUnit}>
            <h1 className={styles.contactHeadline}>ALTSTADT</h1>
            <div className={styles.contactColumn}>
              <p className={styles.contactText}>
                info <br /> @spaceburger.eu <br /> <br /> NEUSTR. 41 <br />{" "}
                40213 DÜSSELDORF <br /> (0211) 863 26 55 <br /> <br />{" "}
                ÖFFNUNGSZEITEN: <br /> <br /> MO – Fr: <br /> 12 - 22.00h <br />{" "}
                <br /> SA: <br /> 13.00 – 22.30h <br /> <br /> SO & FEIERTAGS:{" "}
                <br /> 13.00 – 22.00h
              </p>
            </div>
          </div>
          <div className={styles.contactUnit}>
            <h1 className={styles.contactHeadline}>DERENDORF</h1>
            <div className={styles.contactColumn}>
              <p className={styles.contactText}>
                derendorf <br /> @spaceburger.de <br /> <br /> PFALZSTR. 15{" "}
                <br /> 40477 DÜSSELDORF <br /> (0211) 4849498 <br />
                <br /> ÖFFNUNGSZEITEN: <br /> <br /> MO – Fr: <br /> 12 - 14.30h{" "}
                <br /> 18 - 21h <br /> <br /> SA: <br /> 14.00 – 21h <br />{" "}
                <br /> SO & FEIERTAGS: <br /> 14.00 – 21.00h
              </p>
            </div>
          </div>
        </article>
        <article className={styles.socialMediaSection}>
          <a
            href="https://www.instagram.com/space_burger/"
            target="_blank"
            rel="noreferrer"
          >
            <Insta className={styles.insta} />
          </a>
          <a
            href="https://www.facebook.com/spaceburger.restaurant"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook className={styles.facebook} />
          </a>
        </article>
      </section>
    </div>
  );
};

export default Contact;
