import styles from "./Impressum.module.css";

const Impressum = () => {
  return (
    <section className={styles.impressum}>
      <article className={styles.impressumContainer}>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Space Burger GmbH & Co. KG
          <br />
          Erkrather Str. 356
          <br />
          40231 D&uuml;sseldorf
        </p>

        <p>
          Geschäftsführer:
          <br />
          Pulad Mohammadi
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 (0) 211 8632655
          <br />
          E-Mail: info@spaceburger.eu
          <br />
          Registergericht: Amtsgericht Düsseldorf
          <br />
          Registernummer: HRA 27281
          <br />
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE360839807
          <br />
          <br />
          Persönlich haftende Gesellschafterin der Space Burger GmbH & Co. KG:
          <br />
          Space Burger Verwaltungs GmbH
          <br />
          Geschäftsführer: Pulad Mohammadi
          <br />
          Registergericht: Amtsgericht Düsseldorf
          <br />
          Registernummer: HRB 99913
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </article>
    </section>
  );
};

export default Impressum;
