import { ReactComponent as Welcome } from "../svg/WelcomeSign.svg";
import { ReactComponent as ToTheUniverse } from "../svg/ToTheUniverse.svg";
import VeganDino from "../png/veganDino.png";
import { ReactComponent as WeGotBurgers } from "../svg/WE GOT BURGERS FOR EVERYONE.svg";
import MrSalato from "../png/MrSalato.png";
import { ReactComponent as DownloadOur } from "../svg/DOWNLOAD OUR.svg";
import { ReactComponent as ArrowToButton } from "../svg/ArrowToButton.svg";
import { ReactComponent as ForFreeDeliveryCall } from "../svg/ForFreeDeliveryCall.svg";
import { ReactComponent as OR } from "../svg/OR.svg";

import AstralCat from "../png/Grinsekatze_Pixel.png";

import styles from "./Home.module.css";

const Home = () => {
  return (
    <div className={styles.home} id="home">
      <div className={styles.welcome}>
        <Welcome className={styles.welcomeTypo} />
        <ToTheUniverse className={styles.toTheUniverse} />
      </div>
      <div className={styles.welcomeMain}>
      <img src={VeganDino} className={styles.veganDino} alt="Vegan Dinosaur" ></img>
        <div className={styles.downloadSection}>
          <WeGotBurgers className={styles.weGotBurgers} />
          <div className={styles.downloadArea}>
            <div className={styles.downloadButtons}>
              <DownloadOur className={styles.downloadOur} />
              <div className={styles.buttonZone}>
                <ArrowToButton className={styles.arrowToButton} />
                <a
                  href="http://spaceburger.eu/wp-content/uploads/2020/01/Spaceburger_Men%C3%BC_2020_online.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.button}> GERMAN MENU </button>
                </a>
              </div>
              <div className={styles.buttonZone}>
                <ArrowToButton className={styles.arrowToButton} />
                <a
                  href="http://spaceburger.eu/wp-content/uploads/2021/06/SB_Zeitung_Juli_2021_englisch_small.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.button}> ENGLISH MENU </button>
                </a>
              </div>
            </div>
            <img
              src={MrSalato}
              alt="illustration of a salad person"
              className={styles.mrSalato}
            />
          </div>
        </div>
      </div>
      <div className={styles.delivery}>
        <ForFreeDeliveryCall className={styles.forFreeDeliveryCall} />
        <OR className={styles.or} />
        <img src={AstralCat} className={styles.astralCat} alt="Astral Cat" />
        <a
          href="http://space-burger-dd.de/4118"
          target="_blank"
          rel="noreferrer"
        >
          <button className={styles.button} id={styles.onlineButton}>
            {" "}
            ORDER ONLINE{" "}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Home;
