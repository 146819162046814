import { ReactComponent as AlienHead } from "../svg/AlienHeadWhite.svg";
import { ReactComponent as HeaderTitle } from "../svg/headerTitle.svg";
import { ReactComponent as BigPyramidIcon } from "../svg/BigPyramid.svg";
import { ReactComponent as InstaIcon } from "../svg/instagram_Icon.svg";
import { ReactComponent as FacebookIcon } from "../svg/facebook_icon.svg";
import { useState } from "react";
import Dropdown from "./Dropdown.js";
import styles from "./Header.module.css";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  const [drop, setDrop] = useState(false);

  const dropdownHandler = () => {
    setDrop((drop) => !drop);
  };

  const navClickHandler = (toggleState) => {
    setDrop(toggleState);
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.logo}>
          <Link to="#home">
            <AlienHead className={styles.alienHead} />
          </Link>
          <span className={styles.logoSubtitle}>SINCE 1997</span>
        </div>
        <HeaderTitle className={styles.headerTitle} />
      </div>
      <nav className={styles.nav}>
        <div className={styles.headerSocialMedia}>
          <a
            href="https://www.instagram.com/space_burger/"
            target="_blank"
            rel="noreferrer"
          >
            <InstaIcon className={styles.insta} />
          </a>
          <a
            href="https://www.facebook.com/spaceburger.restaurant"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon className={styles.facebook} />
          </a>
        </div>
        <div className={styles.headerRight}>
          <BigPyramidIcon
            onClick={dropdownHandler}
            className={styles.bigPyramid}
          />
        </div>
      </nav>
      {drop && (
        <Dropdown onNavClick={navClickHandler} className={styles.dropdown} />
      )}
    </div>
  );
};
export default Header;
