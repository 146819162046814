import styles from "./Reservations.module.css";
import FutureBoy from "../png/Marty.png"; 
import ForReservationsCall from "../png/FOR RESERVATIONS CALL.png";

const Reservations = () => {
return (
    <div className={styles.reservationsArea} id="reservations">
        <section className={styles.reservationsSection} >
            <img src={FutureBoy} alt="illustration of boy from the future" className={styles.futureBoy} ></img>
            <img src={ForReservationsCall} alt="a writing saying for reservations call us" className={styles.forReservationsCall} />
        </section>
    </div>
);
};

export default Reservations;