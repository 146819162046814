import styles from "./Footer.module.css";
import Arrow from "../png/arrow_triangle_right.png";
import { useState } from "react";
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";

const Footer = () => {
  const [datenschutzIsActive, setDatenschutzIsActive] = useState(false);
  const [impressumIsActive, setImpressumIsActive] = useState(false);

  const impressumClickHandler = () => {
    setImpressumIsActive((impressumIsActive) => !impressumIsActive);
    datenschutzIsActive && setDatenschutzIsActive(false);
  };

  const datenschutzClickHandler = () => {
    setDatenschutzIsActive((datenschutzIsActive) => !datenschutzIsActive);
    impressumIsActive && setImpressumIsActive(false);
  };

  return (
    <div className={styles.footerArea} id="impressum">
      <section className={styles.footer}>
        <div className={styles.footerLeft}>
          <div className={styles.smContainer}>
            <a
              className={styles.datenschutzHeader}
              onClick={datenschutzClickHandler}
            >
              Datenschutzerklärung
            </a>
            <img
              src={Arrow}
              style={{ transform: datenschutzIsActive ? "rotate(90deg)" : "" }}
              onClick={datenschutzClickHandler}
              alt="Arrow Right"
              className={styles.arrow}
            />
          </div>
          <div className={styles.smContainer}>
            <a
              className={styles.impressumHeader}
              onClick={impressumClickHandler}
            >
              Impressum
            </a>
            <img
              src={Arrow}
              style={{ transform: impressumIsActive ? "rotate(90deg)" : "" }}
              onClick={impressumClickHandler}
              alt="Arrow Right"
              className={styles.arrow}
            />
          </div>
        </div>
        <p className={styles.copyright}>© SPACE BURGER ® 2022</p>
      </section>
      {datenschutzIsActive && <Datenschutz style={{ overflow: "hidden" }} />}
      {impressumIsActive && <Impressum style={{ overflow: "hidden" }} />}
    </div>
  );
};

export default Footer;
