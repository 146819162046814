import { ReactComponent as HomemadeRefreshments } from "../svg/HomeMadeRefreshmentCollection.svg";
import { ReactComponent as ClassicMilkshakes } from "../svg/ClassicMilkshakesCollection.svg";
import { ReactComponent as WeHave } from "../svg/WeHave.svg";

import styles from "./Drinks.module.css";

const Drinks = () => {
  return (
    <div className={styles.drinksArea}>
      <section className={styles.drinksSection} >
        <h1>
        <WeHave className={styles.weHave} />
        </h1>
        <article className={styles.drinksCollection}>
        <HomemadeRefreshments className={styles.homemadeRefreshments} />
        <ClassicMilkshakes className={styles.classicMilkshakes}/>
        </article>
      </section>
    </div>
  );
};

export default Drinks;
